import { useStaticQuery, graphql } from "gatsby"

export const useAllPosts = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query useAllPosts {
        wordpress {
          posts(first: 500) {
            nodes {
              id
              blocks {
                name
                originalContent
              }
              content
              date
              featuredImage {
                altText
                mediaDetails {
                  sizes {
                    file
                    height
                    mimeType
                    name
                    sourceUrl
                    width
                  }
                }
                sourceUrl
              }
              seo {
                title
                focuskw
                metaDesc
                metaKeywords
                opengraphDescription
                opengraphImage {
                  altText
                  mediaDetails {
                    sizes {
                      file
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                  sourceUrl
                }
                opengraphTitle
                twitterDescription
                twitterImage {
                  altText
                  mediaDetails {
                    sizes {
                      file
                      height
                      mimeType
                      name
                      sourceUrl
                      width
                    }
                  }
                  sourceUrl
                }
                twitterTitle
              }
              status
              slug
              uri
              title
            }
          }
        }
      }
    `
  )
  return wordpress.posts.nodes
}

export default useAllPosts
