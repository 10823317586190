import styled from "styled-components"
import device from "particles/mediaQueries"
import { hexToRGB } from "helpers"

const StyledTestimonials = styled.section`
  margin: 96px 0;
  position: relative;
  width: 100%;

  color: ${(props) => props.theme.white};
  fill: transparent;

  @media ${device.md} {
    margin: 128px 0;
  }

  @media ${device.xl} {
    margin: 196px 0;
  }

  @media ${device.xxl} {
    margin: 256px 0;
  }

  p {
    color: ${(props) => props.theme.grey300};
    transition: 0.2s color ease;
  }

  p,
  q {
    &::before {
      left: 16px;
    }

    &::after {
      right: 16px;
    }
  }

  .slick-slide {
    cursor: grab;
  }

  .slick-dots {
    text-align: left;

    @media ${device.md} {
      padding: 0 64px;
    }

    li:first-of-type {
      margin-left: 0;
    }

    li button:before {
      color: ${(props) => props.theme.white};
      transition: 0.2s color ease, 0.2s border ease;
    }

    li.slick-active button:before {
      align-items: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;

      border: 2px solid ${(props) => props.theme.white};
      color: ${(props) => props.theme.white};
      opacity: 1;
    }
  }

  .testimonials__contents {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @media ${device.xl} {
      margin: 0 auto;
      max-width: 80%;
    }
  }

  .testimonial {
    flex: 1;
    padding: 0;
    position: relative;

    @media ${device.md} {
      padding: 24px 64px;
    }
  }

  .testimonial__author {
    align-items: center;
    display: inline-flex;
    margin-right: 12px;

    &::after {
      content: "";
      display: inline-block;
      height: 2px;
      margin-left: 12px;
      width: 20px;

      background-color: ${(props) => props.theme.white};
    }
  }
`

export default StyledTestimonials
