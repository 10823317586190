import styled from "styled-components"
import device from "particles/mediaQueries"
import { hexToRGB } from "helpers"

const dotBackground = (props) => {
  const background = `
    linear-gradient(to right, ${props.theme.purple} 50%, transparent 0%),
    linear-gradient(${props.theme.primary} 50%, transparent 0%),
    linear-gradient(to right, ${props.theme.green} 50%, transparent 0%),
    linear-gradient(${props.theme.red} 50%, transparent 0%);
  `
  return background
}

const StyledProcess = styled.section`
  margin: 96px 0;
  position: relative;
  width: 100%;

  color: ${(props) => props.theme.white};
  fill: transparent;

  @media ${device.md} {
    margin: 128px 0;
  }

  @media ${device.xl} {
    margin: 196px 0;
  }

  @media ${device.xxl} {
    margin: 256px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 55ch;

    color: ${(props) => props.theme.grey300};
    transition: 0.2s color ease;

    @media ${device.lg} {
      font-size: 1.6rem;
    }

    @media ${device.xxl} {
      font-size: inherit;
    }
  }

  .processes__contents {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    width: 100%;

    @media ${device.lg} {
      display: grid;
    }

    @media ${device.xl} {
      grid-gap: 64px;
    }

    @media ${device.xxl} {
      grid-gap: 96px;
    }
  }

  .process {
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    grid-column: span 4;
    position: relative;

    @media ${device.xl} {
      display: flex;
    }

    &:hover {
      .process__dot {
        background-size: 5px 2px, 2px 5px;
        filter: grayscale(0);
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    & + .process {
      margin-top: 32px;

      @media ${device.lg} {
        margin-top: 0;
      }
    }
  }

  .process__content {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 24px;

    text-align: center;

    @media ${device.xl} {
      display: flex;
    }
  }

  .process__dot {
    border-radius: 50%;
    display: none;
    height: 0;
    left: 50%;
    padding-top: calc(100% + 96px);
    position: absolute;
    top: 50%;
    width: calc(100% + 96px + 4px);
    z-index: -1;

    background: ${(props) => dotBackground(props)};
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 5px 1px, 1px 5px;
    filter: grayscale(0.2);
    transform: translate(-50%, -50%);
    transition: 1s background-size ease, 1s filter ease;

    @media ${device.xl} {
      display: block;

      animation-duration: 15s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: processRotate;
      animation-timing-function: linear;
    }

    @media ${device.xxl} {
      padding-top: calc(100% + 128px);
      width: calc(100% + 128px + 4px);
    }
  }

  .process:nth-of-type(2) {
    .process__dot {
      animation-delay: 7.5s;
    }
  }

  .process:nth-of-type(3) {
    .process__dot {
      animation-delay: 15s;
    }
  }

  @keyframes processRotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`

export default StyledProcess
