import styled from "styled-components"
import device from "particles/mediaQueries"
import { hexToRGB } from "helpers"

const textShadow = (props) => {
  const rgbBlack = hexToRGB(props.theme.black)

  let shadow = `0px 4px 3px rgba(${rgbBlack}, 0.4),`
  shadow += `0px 8px 13px rgba(${rgbBlack}, 0.1),`
  shadow += `0px 18px 23px rgba(${rgbBlack}, 0.1)`

  return shadow
}

const StyledPosts = styled.section`
  margin: 96px 0;
  position: relative;
  width: 100%;

  color: ${(props) => props.theme.white};
  fill: transparent;
  text-shadow: ${(props) => textShadow(props)};

  @media ${device.xs} {
    margin: 128px 0;
  }

  @media ${device.lg} {
    margin: 196px 0;
  }

  @media ${device.xlg} {
    margin: 256px 0;
  }

  a {
    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      &::after {
        display: none;
      }
    }

    &:focus,
    &:focus-within {
      outline: 1px dashed ${(props) => props.theme.white};
      outline-offset: 0px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    margin-top: 0;
  }

  img,
  picture,
  video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    object-fit: cover;
    transition: 1s left ease, 1s top ease;
    transition-delay: 0.4s;
  }

  p {
    color: ${(props) => props.theme.grey300};
    transition: 0.2s color ease;
  }

  .posts__contents {
    display: flex;
    flex-direction: column;
    grid-gap: 48px;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    z-index: 2;

    @media ${device.xs} {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media ${device.lg} {
      display: grid;
    }

    @media ${device.xl} {
      grid-gap: 64px;
    }

    @media ${device.xxl} {
      grid-gap: 96px;
    }
  }

  .post {
    display: flex;
    flex-direction: column;

    grid-row: span 1;
    grid-column: span 4;
    min-height: 200px;
    position: relative;
    width: 100%;

    @media ${device.xs} {
      width: calc(50% - 16px);
    }

    @media ${device.lg} {
      min-height: 0;
      width: 100%;
    }

    @media ${device.xl} {
      grid-column: span 3;
    }
  }

  .post + .post {
    margin-top: 32px;

    @media ${device.xs} {
      margin-top: 0;
    }
  }

  .post:nth-of-type(1n + 3) {
    @media ${device.xs} {
      margin-top: 32px;
    }

    @media ${device.lg} {
      margin-top: 0;
    }
  }

  .post:nth-of-type(2n) {
    @media ${device.xs} {
      margin-left: 32px;
    }

    @media ${device.lg} {
      margin-left: 0;
    }
  }

  .post__content {
    order: 1;
    margin-top: 24px;
    position: relative;
    z-index: 3;

    opacity: 1;
    transition: 1s opacity ease;
  }

  .post__media {
    order: 0;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
  }

  .post__link {
    z-index: 4;
  }

  .post__link--media {
    display: block;
  }
`

export default StyledPosts
