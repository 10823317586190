import React, { useRef } from "react"
import { useInView } from "react-intersection-observer"
import { generateID } from "helpers"

import StyledProcess from "./process.styles"

import ParseHTML from "particles/parseHTML"

import CTA from "atoms/cta/cta"
import Link from "atoms/link/link"

const inViewConfig = {
  threshold: 0,
  triggerOnce: true,
}

const Process = ({ items }) => {
  const hasItems = items && items.length > 0
  if (!hasItems) return null

  return (
    <StyledProcess className="processes">
      <div className="grid">
        <div className="processes__contents">
          {items.map((item) => (
            <ProcessSingle key={item.id ? item.id : generateID()} {...item} />
          ))}
        </div>
      </div>
    </StyledProcess>
  )
}

const ProcessSingle = (props) => {
  const { description, title } = props
  const [ref, inView, entry] = useInView(inViewConfig)

  return (
    <div className="process" ref={ref}>
      <div className="process__dot" />
      <div className="process__content">
        {title && <h3 className="process__title">{title}</h3>}
        {description && ParseHTML(description)}
      </div>
    </div>
  )
}

export default Process
