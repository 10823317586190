import React from "react"
import { generateID, hasBrowser } from "helpers"
import Slider from "react-slick"
import device from "particles/mediaQueries"

import StyledTestimonials from "./testimonials.styles"

import CTA from "atoms/cta/cta"
import Link from "atoms/link/link"
import Video from "atoms/video/video"

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Testimonials = ({ items }) => {
  const hasItems = items && items.length > 0
  if (!hasItems) return null

  const mq = hasBrowser() && window.matchMedia(device.md)
  const isMobile = !mq.matches

  return (
    <StyledTestimonials className="testimonials">
      <div className="grid">
        <div className="testimonials__contents">
          <Slider {...settings} adaptiveHeight={isMobile}>
            {items.map((item, i) => (
              <Testimonial key={item.id ? item.id : generateID()} {...item} />
            ))}
          </Slider>
        </div>
      </div>
    </StyledTestimonials>
  )
}

const Testimonial = (props) => {
  const { author, avatar, company, quote, role } = props
  const hasAuthor = author && company && role

  return (
    <blockquote className="testimonial">
      <div className="testimonial__content">
        {quote && <q className="testimonial__quote">{quote}</q>}
        {hasAuthor && (
          <cite className="testimonial__cite">
            <span className="testimonial__author">{author}</span>
            <span className="testimonial__role">{role} at </span>
            <span className="testimonial__company">{company}</span>
          </cite>
        )}
      </div>
    </blockquote>
  )
}

export default Testimonials
