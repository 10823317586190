import React, { useRef } from "react"
import he from "he"
import { useInView } from "react-intersection-observer"
import { generateID } from "helpers"

import StyledPosts from "./posts.styles"

import useAllPosts from "particles/hooks/useAllPosts"

import CTA from "atoms/cta/cta"
import Link from "atoms/link/link"
import Video from "atoms/video/video"

const inViewConfig = {
  threshold: 0,
  triggerOnce: true,
}

const Posts = ({ items, show_all }) => {
  let classList = `posts`
  if (!show_all) classList += ` posts-curated`
  if (show_all) classList += ` posts-all`
  if (show_all) items = useAllPosts()
  const hasItems = items && items.length > 0
  if (!hasItems) return null

  return (
    <StyledPosts className={classList}>
      <div className="grid">
        <div className="posts__contents">
          {items.map((item) => (
            <SinglePost
              key={item.id ? item.id : generateID()}
              {...item}
              show_all={show_all}
            />
          ))}
        </div>
      </div>
    </StyledPosts>
  )
}

const SinglePost = (props) => {
  const { featuredImage, seo, show_all, slug } = props
  let { description, image, title, video } = props

  let classList = `post`
  const defaultSEO = seo?.metaDesc
  const defaultImage = featuredImage
  const defaultTitle = title
  const defaultLink = {
    target: null,
    title: `Continue reading ${title}`,
    url: `/${slug}`,
  }
  const defaultVideo = null

  description = description ? description : defaultSEO ? defaultSEO : ``
  image = defaultImage
  const link = defaultLink
  title = title ? title : defaultTitle
  video = video ? video : defaultVideo

  const [ref, inView, entry] = useInView(inViewConfig)
  const hasMedia = image || video
  const random = Math.random() >= 0.5
  if (random) classList += ` post--alternate`
  if (inView) classList += ` post--inview`

  const PostMedia = () => (
    <div className="post__media">
      {image && <img src={image.sourceUrl} alt={image.altText} />}
      {inView && video && (
        <Video className="post__video" video={video.mediaItemUrl} />
      )}
    </div>
  )

  return (
    <div className={classList} ref={ref}>
      <div className="post__content">
        {show_all && title && (
          <h2 className="h3 post__title">{he.decode(title)}</h2>
        )}
        {!show_all && title && (
          <h3 className="post__title">{he.decode(title)}</h3>
        )}
        {description && (
          <p className="post__description">{he.decode(description)}</p>
        )}
        {link && <CTA className="post__cta">Continue reading</CTA>}
      </div>
      {hasMedia && (
        <Link
          className="post__link--media"
          href={link.url}
          target={link.target}
        >
          <PostMedia />
        </Link>
      )}
      {link && (
        <Link className="post__link" href={link.url} target={link.target}>
          <span className="hide">{he.decode(link.title)}</span>
        </Link>
      )}
    </div>
  )
}

export default Posts
